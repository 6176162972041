export default [
  {
    header: "Classes",
  },
  {
    title: "Calendar",
    route: "forca-calendar",
    icon: "CalendarIcon",
  },
  {
    title: "Financeiro",
    route: "forca-financeiro",
    icon: "DollarSignIcon",
  },
  {
    title: "Ajustes",
    icon: "SettingsIcon",
    route: "forca-ajustes",
  },
];
